import Menu from "antd/lib/menu";

const isTest =
  window.location.hostname.startsWith("test.") ||
  process.env.NODE_ENV === "development";

function ServicesDropdown() {
  return (
    <Menu style={{ maxWidth: 200 }}>
      <Menu.Item key="vacancy">
        <a
          rel="noopener noreferrer"
          href="https://resume.yanao.2035.university/"
          // {
          //   isTest
          //     ? "https://test.bmk-map.2035.university"
          //     : "https://bmk-map.2035.university"
          // }
        >
          Резюме
        </a>
      </Menu.Item>
      <Menu.Item key="junior">
        <a
          rel="noopener noreferrer"
          href="https://staff.yanao.2035.university/"
          // {
          //   isTest
          //     ? "https://test.junior.bmk-map.2035.university"
          //     : "https://junior.bmk-map.2035.university"
          // }
        >
          Кадры
        </a>
      </Menu.Item>
      <Menu.Item key="trends">
        <a
          rel="noopener noreferrer"
          href="https://trends.yanao.2035.university/"
          // {
          //   isTest
          //     ? "https://test.trends.bmk-map.2035.university"
          //     : "https://trends.bmk-map.2035.university"
          // }
        >
          Тренды
        </a>
      </Menu.Item>
    </Menu>
  );
}

export default ServicesDropdown;
